import { Container, Text } from '@pixi/react'
import * as PIXI from 'pixi.js'
import { useCallback, useMemo } from 'react'
import { useGrid } from '../../../contexts/grid'
import useGetUserById from '../../../hooks/useGetUserById'
import useTextStyle from '../../../hooks/useTextStyle'

interface Props {
	points: { x: number; y: number }[]
	previewPoint?: { x: number; y: number } | null
	isClosed: boolean
	showCloseLabel?: boolean
	userId?: string
}

export default function MeasurementDisplay({
	points,
	previewPoint,
	isClosed,
	showCloseLabel = false,
	userId,
}: Props) {
	const { grid, hexGrid } = useGrid()
	const textStyle = useTextStyle()
	const totalTextStyle = useTextStyle(1.5) // 1.5x scale for total distance
	const { user } = useGetUserById(userId)
	const userColor = user?.userSettings?.color || '#888888'

	// Calculate distance between two points
	const calculateDistance = useCallback(
		(p1: { x: number; y: number }, p2: { x: number; y: number }) => {
			if (grid.type === 'square') {
				const dx = p2.x - p1.x
				const dy = p2.y - p1.y
				return Math.sqrt(dx * dx + dy * dy) / grid.size
			}

			// For hex grids, convert to hex coordinates and count steps
			if (!hexGrid) return 0

			const hex1 = hexGrid.pointToHex(p1)
			const hex2 = hexGrid.pointToHex(p2)
			if (!hex1 || !hex2) return 0

			const hexDx = hex2.col - hex1.col
			const hexDy = hex2.row - hex1.row
			return (Math.abs(hexDx) + Math.abs(hexDy) + Math.abs(hexDx + hexDy)) / 2
		},
		[grid.type, grid.size, hexGrid],
	)

	// Memoize segment calculations
	const segments = useMemo(() => {
		const result = []
		for (let i = 0; i < points.length - 1; i++) {
			const start = points[i]
			const end = points[i + 1]
			const distance = calculateDistance(start, end) * grid.unitSize

			// Calculate angle for text rotation
			const angle = Math.atan2(end.y - start.y, end.x - start.x)
			const normalizedAngle =
				((angle % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI)
			const displayAngle =
				normalizedAngle > Math.PI / 2 && normalizedAngle <= (3 * Math.PI) / 2
					? angle + Math.PI
					: angle

			// Calculate offset perpendicular to line
			const perpAngle = angle + Math.PI / 2
			const offset = grid.size * 0.3
			const midpoint = {
				x: (start.x + end.x) / 2 + Math.cos(perpAngle) * offset,
				y: (start.y + end.y) / 2 + Math.sin(perpAngle) * offset,
			}

			result.push({
				distance,
				position: midpoint,
				rotation: (displayAngle * 180) / Math.PI,
			})
		}

		// Add preview segment if we have a preview point
		if (previewPoint && points.length > 0) {
			const start = points[points.length - 1]
			const end = previewPoint
			const distance = calculateDistance(start, end) * grid.unitSize

			const angle = Math.atan2(end.y - start.y, end.x - start.x)
			const normalizedAngle =
				((angle % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI)
			const displayAngle =
				normalizedAngle > Math.PI / 2 && normalizedAngle <= (3 * Math.PI) / 2
					? angle + Math.PI
					: angle

			const perpAngle = angle + Math.PI / 2
			const offset = grid.size * 0.3
			const midpoint = {
				x: (start.x + end.x) / 2 + Math.cos(perpAngle) * offset,
				y: (start.y + end.y) / 2 + Math.sin(perpAngle) * offset,
			}

			result.push({
				distance,
				position: midpoint,
				rotation: (displayAngle * 180) / Math.PI,
				isPreview: true,
			})
		}

		return result
	}, [points, previewPoint, calculateDistance, grid.unitSize, grid.size])

	// Calculate total distance only when needed
	const totalDistance = useMemo(() => {
		if (!isClosed || points.length < 2) return 0
		return segments.reduce(
			(sum, segment) => (!segment.isPreview ? sum + segment.distance : sum),
			0,
		)
	}, [segments, isClosed, points.length])

	const modifiedTextStyle = useMemo(() => {
		return new PIXI.TextStyle({
			...textStyle,
			fill: userColor,
		})
	}, [textStyle, userColor])

	const modifiedTotalTextStyle = useMemo(() => {
		return new PIXI.TextStyle({
			...totalTextStyle,
			fill: userColor,
		})
	}, [totalTextStyle, userColor])

	if (points.length < 2 && (!previewPoint || points.length === 0)) return null

	return (
		<Container sortableChildren>
			{/* Render segment distances */}
			{segments.map(({ distance, position, rotation }, i) => {
				// Don't show the last segment's distance if we're showing the close label
				if (showCloseLabel && i === segments.length - 1) return null

				return (
					<Text
						key={i}
						text={`${Math.round(distance)}${grid.unit}`}
						x={position.x}
						y={position.y}
						anchor={0.5}
						angle={rotation}
						style={modifiedTextStyle}
						resolution={150}
						alpha={1}
					/>
				)
			})}

			{/* Show "Close" text instead of distance for last segment when hovering */}
			{showCloseLabel && segments.length > 0 && (
				<Text
					text='Close'
					x={segments[segments.length - 1].position.x}
					y={segments[segments.length - 1].position.y}
					anchor={0.5}
					angle={segments[segments.length - 1].rotation}
					style={modifiedTextStyle}
					resolution={150}
					alpha={1}
				/>
			)}

			{/* Render total distance if measurement is closed */}
			{isClosed && points.length >= 2 && (
				<Container>
					<Text
						text={`${Math.round(totalDistance)}${grid.unit}`}
						x={points[points.length - 1].x}
						y={points[points.length - 1].y - grid.size * 0.3}
						anchor={0.5}
						style={modifiedTotalTextStyle}
						resolution={150}
						alpha={1}
					/>
				</Container>
			)}
		</Container>
	)
}

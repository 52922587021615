import {
	Crown,
	Dices,
	FileSpreadsheet,
	Settings as SettingsIcon,
	Users,
} from 'lucide-react'
import { FC, useContext, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import AuthContext from '../../contexts/auth'
import GameContext from '../../contexts/game'
import { UserLevel } from '../../interfaces/auth'
import CloseButton from '../FormComponents/CloseButton'
import XlType from '../interface/text/XlType'
import SidebarModalWindow from '../window/SidebarModalWindow'
import Admin from './Admin'
import SettingsDice from './SettingsDice'
import SettingsGame from './SettingsGame'
import SettingsPlayers from './SettingsPlayers'
import SettingsSheets from './SettingsSheets'

interface ISettings {
	onClose: () => void
	isOpen: boolean
	defaultTabId?: string
}

const Settings: FC<ISettings> = ({ onClose, isOpen, defaultTabId }) => {
	const { game } = useContext(GameContext)
	const { authState } = useContext(AuthContext)
	const [enableClickOutside, setEnableClickOutside] = useState(true)
	const isAdmin = authState.userDetails.userLevel === UserLevel.ADMIN

	const methods = useForm({
		defaultValues: {
			title: game.title,
			description: game.description,
		},
	})

	const handleClose = () => {
		if (methods.formState.isDirty) {
			const confirmClose = window.confirm(
				'You have unsaved changes. Are you sure you want to close?',
			)
			if (!confirmClose) {
				return
			}
		}
		onClose()
	}

	const tabs = [
		{
			id: 'game',
			label: 'Game',
			icon: <Crown className='h-4 w-4' />,
			content: <SettingsGame />,
		},
		{
			id: 'players',
			label: 'Players',
			icon: <Users className='h-4 w-4' />,
			accessory: (game.users.length + 1).toString(),
			content: <SettingsPlayers />,
		},
		{
			id: 'sheets',
			label: 'Sheets',
			icon: <FileSpreadsheet className='h-4 w-4' />,
			accessory: game.pdfSheets?.allIds.length.toString() || '0',
			content: <SettingsSheets setEnableClickOutside={setEnableClickOutside} />,
		},
		{
			id: 'dice',
			label: 'Dice',
			icon: <Dices className='h-4 w-4' />,
			content: <SettingsDice />,
		},
		...(isAdmin
			? [
					{
						id: 'admin',
						label: 'Admin',
						icon: <SettingsIcon className='h-4 w-4' />,
						content: (
							<Admin
								onClose={onClose}
								setEnableClickOutside={setEnableClickOutside}
							/>
						),
					},
			  ]
			: []),
	]

	return (
		<FormProvider {...methods}>
			<SidebarModalWindow
				id='settings'
				title='Settings'
				onClose={handleClose}
				size='large'
				enableClickOutside={enableClickOutside}
				tabs={tabs}
				isOpen={isOpen}
				defaultTabId={defaultTabId}
				hideHeader
				sidebarWidth={220}
				sidebarHeader={
					<div className='my-6 flex flex-col gap-6'>
						<div className='-ml-2'>
							<CloseButton onClick={handleClose} />
						</div>
						<XlType>Settings</XlType>
					</div>
				}
			/>
		</FormProvider>
	)
}

export default Settings

import { Graphics } from '@pixi/react'
import * as PIXI from 'pixi.js'
import { useCallback } from 'react'
import useGetUserById from '../../../hooks/useGetUserById'

interface Props {
	points: { x: number; y: number }[]
	isPreview?: boolean
	isOtherUser?: boolean
	userId?: string
}

export default function MeasurementLine({
	points,
	isPreview = false,
	isOtherUser = false,
	userId,
}: Props) {
	const { user } = useGetUserById(userId)
	const userColor = user?.userSettings?.color || '#888888'
	const color = parseInt(userColor.replace('#', '0x'))

	const draw = useCallback(
		(g: PIXI.Graphics) => {
			g.clear()

			if (isPreview) {
				// Draw dashed preview line manually
				if (points.length >= 2) {
					const dashLength = 0.5
					const gapLength = 0.5
					const start = points[0]
					const end = points[1]

					const dx = end.x - start.x
					const dy = end.y - start.y
					const distance = Math.sqrt(dx * dx + dy * dy)

					const numDashes = Math.floor(distance / (dashLength + gapLength))
					const stepX = dx / numDashes
					const stepY = dy / numDashes

					g.lineStyle({
						width: 0.2,
						color: color,
						alpha: 1,
						cap: PIXI.LINE_CAP.ROUND,
						join: PIXI.LINE_JOIN.ROUND,
					})

					for (let i = 0; i < numDashes; i++) {
						const x1 = start.x + i * stepX
						const y1 = start.y + i * stepY
						const x2 = start.x + i * stepX + stepX * 0.5
						const y2 = start.y + i * stepY + stepY * 0.5

						g.moveTo(x1, y1)
						g.lineTo(x2, y2)
					}
				}
			} else {
				// Draw solid line
				g.lineStyle({
					width: 0.2,
					color: color, // Always use user's color
					alpha: 1, // Full opacity for all rulers
					cap: PIXI.LINE_CAP.ROUND,
					join: PIXI.LINE_JOIN.ROUND,
				})

				if (points.length > 0) {
					points.forEach((point, i) => {
						if (i === 0) {
							g.moveTo(point.x, point.y)
						} else {
							g.lineTo(point.x, point.y)
						}
					})
				}
			}
		},
		[points, isPreview, isOtherUser, color],
	)

	return <Graphics draw={draw} zIndex={isOtherUser ? 999 : 1000} />
}

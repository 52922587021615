export const INTERFACE_BACKGROUND_COLOR = '#F8F6F1'
export const INTERFACE_BACKGROUND_COLOR_HOVER = '#D09A3A33'
export const INTERFACE_BRIGHT_GOLD_COLOR = '#D09A3A'
export const INTERFACE_DROPSHADOW = `
	0 3.8px 2.2px rgba(0, 0, 0, 0.028), 
	0 9.2px 5.3px rgba(0, 0, 0, 0.04), 
	0 17.3px 10px rgba(0, 0, 0, 0.05), 
	0 30.8px 17.9px rgba(0, 0, 0, 0.06), 
	0 57.7px 33.4px rgba(0, 0, 0, 0.072), 
	0 138px 80px rgba(0, 0, 0, 0.1)
`

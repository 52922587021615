import { twMerge } from 'tailwind-merge'
import { IScene } from '../../../../shared/types/scene'
import useGetThumbnailAsset from '../../hooks/useGetThumbnailAsset'
import Menu from '../documentmenu/Menu'
import AccessDots from './AccessDots'
import ActivateSceneButton from './ActivateSceneButton'
import MapIndicator from './MapIndicator'
import DocumentWindowMenuButton from './MenuButton'

type Props = {
	document: IScene
	className?: string
	showMenuButton?: boolean
}

export default function SceneDocument({
	document,
	className,
	showMenuButton = true,
}: Props) {
	// Early return if document or values is undefined
	if (!document?.values) return null

	const { name, mapId } = document.values
	const thumbnailAsset = useGetThumbnailAsset(document._id)

	const DocumentContent = () => (
		<div className='flex flex-1 justify-between'>
			<div className='flex flex-col'>
				<div className='mt-0.5 truncate text-base'>{name}</div>
				<div className='flex space-x-1'>
					{/* <SmallUppercase>{singularName}</SmallUppercase> */}
					<AccessDots document={document} />
					<MapIndicator mapId={mapId} />
				</div>
			</div>

			<Menu document={document}>
				<DocumentWindowMenuButton show={showMenuButton} />
			</Menu>
		</div>
	)

	return (
		<div
			className={twMerge(
				'mb-1 flex aspect-[2.75] flex-auto cursor-pointer overflow-hidden rounded-xl',
				className,
			)}
			style={{
				backgroundImage: `url(${thumbnailAsset?.fileurl})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			}}
		>
			<div className='-mx-2 flex flex-1 flex-row items-center space-x-2 overflow-hidden bg-black/40 py-1.5 px-4 transition-all duration-300 hover:bg-black/30'>
				<ActivateSceneButton sceneId={document._id} />
				<DocumentContent />
			</div>
		</div>
	)
}

import React, { useMemo } from 'react'
import { DiceMessage, IMessage } from '../../../../shared/types/log'
import ChatbotMessage from './ChatBotMessage'
import Document from './Document'
import MediaMessage from './MediaMessage'
import SimpleDiceMessage from './SimpleDiceMessage'
import SystemMessage from './SystemMessage'
import TextMessage from './TextMessage'

type Props = {
	message: IMessage
	onDelete: () => void
	showSender: boolean
}

const isDiceMessage = (message: IMessage): message is DiceMessage => {
	return message.type === 'dice' && 'diceResults' in message
}

const Message: React.FC<Props> = React.memo(props => {
	const messageContent = useMemo(() => {
		switch (props.message.type) {
			case 'chatbot':
				return <ChatbotMessage {...props} showSender={true} />

			case 'dice':
				if (isDiceMessage(props.message)) {
					return (
						<SimpleDiceMessage
							{...props}
							message={props.message}
							showSender={true}
						/>
					)
				}
				return null

			case 'message':
				return <TextMessage {...props} showSender={true} />

			case 'media':
				return <MediaMessage {...props} />

			case 'system':
				return <SystemMessage {...props} />

			case 'document':
				return <Document {...props} />

			case 'folder':
				return <div>ehm folder? fix this</div>

			default:
				return null
		}
	}, [props])

	return <div>{messageContent}</div>
})

Message.displayName = 'Message'

export default Message

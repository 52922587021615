import { Ruler } from 'lucide-react'
import { forwardRef, useContext } from 'react'
import ToolsContext from '../../contexts/tools'
import useUserLevel from '../../hooks/useUserLevel'
import ToolButton from '../interface/toolbar/ToolButton'

const MeasureButton = forwardRef<HTMLButtonElement, Record<never, never>>(
	(_, ref) => {
		const { toolsState, dispatchTools } = useContext(ToolsContext)
		const { mode } = toolsState
		const { isAtLeastAdept } = useUserLevel()

		const handleMeasureModeClick = () => {
			console.log('MeasureButton: current mode:', mode)
			if (mode !== 'measure') {
				console.log('MeasureButton: activating measure mode')
				dispatchTools({ type: 'SET_MODE', payload: 'measure' })
			} else {
				console.log('MeasureButton: deactivating measure mode')
				dispatchTools({ type: 'CLEAR_MEASURE_POINTS' })
				dispatchTools({ type: 'SET_MODE', payload: 'select' })
			}
		}

		if (!isAtLeastAdept()) return null

		return (
			<ToolButton
				tooltip='Ruler'
				isOpen={mode === 'measure'}
				onClick={handleMeasureModeClick}
				ref={ref}
			>
				<Ruler className='h-5 w-5' aria-hidden='true' />
			</ToolButton>
		)
	},
)

MeasureButton.displayName = 'MeasureButton'

export default MeasureButton

import { Container } from '@pixi/react'
import { Viewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import { useEffect, useState } from 'react'
import { useGrid } from '../../../contexts/grid'
import { useSnapToGrid } from '../../../hooks/useSnapToGrid'
import useUser from '../../../hooks/useUser'
import MeasurementDisplay from './MeasurementDisplay'
import MeasurementLine from './MeasurementLine'

interface Props {
	points: { x: number; y: number }[]
	viewport: Viewport
	isClosed: boolean
	onPointAdded: (point: { x: number; y: number }) => void
	onMeasurementClosed: () => void
}

interface DragState {
	pointIndex: number | null
	startPos: { x: number; y: number } | null
	isOverMap: boolean
	hasMoved: boolean
}

export default function MeasurementInteractionLayer({
	points,
	viewport,
	isClosed,
	onPointAdded,
	onMeasurementClosed,
}: Props) {
	const { grid, mapDimensions, resumeViewport } = useGrid()
	const snapToGrid = useSnapToGrid()
	const { userId } = useUser()

	const [cursorPoint, setCursorPoint] = useState<{
		x: number
		y: number
	} | null>(null)
	const [dragState, setDragState] = useState<DragState>({
		pointIndex: null,
		startPos: null,
		isOverMap: false,
		hasMoved: false,
	})
	const [wasDragging, setWasDragging] = useState(false)
	const [showCloseLabel, setShowCloseLabel] = useState(false)
	const [pendingPoint, setPendingPoint] = useState<{
		x: number
		y: number
	} | null>(null)

	useEffect(() => {
		function handleKeyDown(e: KeyboardEvent) {
			if (e.key === 'Escape' && !isClosed) {
				onMeasurementClosed()
			}
		}
		window.addEventListener('keydown', handleKeyDown)
		return () => window.removeEventListener('keydown', handleKeyDown)
	}, [isClosed, onMeasurementClosed])

	// If the measurement is open, hide the "close label" unless you're doing something special
	// (shown as false here for brevity)
	useEffect(() => {
		if (!isClosed && points.length > 0) setShowCloseLabel(false)
		else setShowCloseLabel(false)
	}, [points.length, isClosed])

	// If we have a pending point, start a new ruler
	useEffect(() => {
		if (pendingPoint) {
			console.log('Starting new ruler from pending point:', pendingPoint)
			onPointAdded(pendingPoint)
			setPendingPoint(null)
		}
	}, [pendingPoint, onPointAdded])

	function handlePointerDown(e: PIXI.FederatedPointerEvent) {
		setDragState({
			pointIndex: null,
			startPos: { x: e.globalX, y: e.globalY },
			isOverMap: true,
			hasMoved: false,
		})
	}

	function handlePointerMove(e: PIXI.FederatedPointerEvent) {
		// Check drag distance
		if (dragState.startPos) {
			const dx = e.globalX - dragState.startPos.x
			const dy = e.globalY - dragState.startPos.y
			const distanceMoved = Math.sqrt(dx * dx + dy * dy)
			if (distanceMoved > grid.size * 0.5) {
				setDragState(prev => ({ ...prev, hasMoved: true }))
				setWasDragging(true)
			}
		}

		// Update cursor position (snap if grid is enabled)
		const worldPos = viewport.toWorld(e.global.x, e.global.y)
		const snappedPoint = grid.enabled
			? snapToGrid(worldPos.x, worldPos.y)
			: { x: worldPos.x, y: worldPos.y }
		setCursorPoint(snappedPoint)

		// Check if we're near the last point to show close label
		if (!isClosed && points.length > 0) {
			const lastPoint = points[points.length - 1]
			const dx = lastPoint.x - snappedPoint.x
			const dy = lastPoint.y - snappedPoint.y
			const distance = Math.sqrt(dx * dx + dy * dy)
			setShowCloseLabel(distance < grid.size * 0.33)
		} else {
			setShowCloseLabel(false)
		}
	}

	function handlePointerUp(_e: PIXI.FederatedPointerEvent) {
		resumeViewport()
		setDragState({
			pointIndex: null,
			startPos: null,
			isOverMap: false,
			hasMoved: false,
		})
		setWasDragging(false)
		setShowCloseLabel(false)
	}

	function handleClick(e: PIXI.FederatedPointerEvent) {
		if (wasDragging) {
			console.log('Click ignored - was dragging')
			setWasDragging(false)
			return
		}

		const worldPos = viewport.toWorld(e.global.x, e.global.y)
		const snappedPoint = grid.enabled
			? snapToGrid(worldPos.x, worldPos.y)
			: { x: worldPos.x, y: worldPos.y }

		// If the measurement is closed, start a new ruler
		if (isClosed) {
			console.log('Starting new ruler at', snappedPoint)
			setPendingPoint(snappedPoint)
			onMeasurementClosed()
			setCursorPoint(null)
			return
		}

		// Check if we should close the measurement
		if (points.length > 0) {
			const lastPoint = points[points.length - 1]
			const dx = lastPoint.x - snappedPoint.x
			const dy = lastPoint.y - snappedPoint.y
			const distance = Math.sqrt(dx * dx + dy * dy)
			const isNearLastPoint = distance < grid.size * 0.33

			// Close on double-click or when clicking near last point with close label shown
			if (e.detail > 1 || (isNearLastPoint && showCloseLabel)) {
				console.log('Closing ruler - double click or confirmed close')
				onMeasurementClosed()
				setCursorPoint(null)
				setShowCloseLabel(false)
				return
			}
		}

		// Otherwise, add a new point
		console.log('Adding new point to ruler at', snappedPoint)
		onPointAdded(snappedPoint)
	}

	const fixedPoints = points
	const previewPoints =
		!isClosed && cursorPoint && points.length > 0
			? [points[points.length - 1], cursorPoint]
			: []

	return (
		<Container
			hitArea={
				new PIXI.Rectangle(0, 0, mapDimensions.width, mapDimensions.height)
			}
			eventMode='static'
			pointerdown={handlePointerDown}
			pointermove={handlePointerMove}
			pointerup={handlePointerUp}
			pointerupoutside={handlePointerUp}
			pointertap={handleClick}
		>
			{/* Current user's ruler */}
			{fixedPoints.length > 0 && (
				<>
					<MeasurementLine points={fixedPoints} userId={userId} />
					<MeasurementDisplay
						points={fixedPoints}
						previewPoint={!isClosed ? cursorPoint : null}
						isClosed={isClosed}
						showCloseLabel={showCloseLabel}
						userId={userId}
					/>
				</>
			)}

			{/* Preview line (last point -> cursor) */}
			{previewPoints.length > 0 && (
				<>
					<MeasurementLine points={previewPoints} userId={userId} isPreview />
					<MeasurementDisplay
						points={previewPoints}
						isClosed={false}
						userId={userId}
					/>
				</>
			)}
		</Container>
	)
}

import { useContext, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { IMessage } from '../../../../shared/types/log'
import GameContext from '../../contexts/game'
import useGetAvatarSrc from '../../hooks/useGetAvatarSrc'
import useGetDocumentById from '../../hooks/useGetDocumentById'
import useGetUserById from '../../hooks/useGetUserById'
import Avatar from '../Avatar'
import SmallUppercase from '../interface/text/SmallUppercase'
import MessageBubble from './Bubble'
import MessageMenu from './MessageMenu'

interface TextMessageProps {
	message: IMessage
	onDelete: () => void
	showSender?: boolean
	className?: string
	style?: React.CSSProperties
}

const TextMessage = ({
	message,
	onDelete,
	className,
	style,
	showSender,
}: TextMessageProps) => {
	const { game } = useContext(GameContext)
	const { user } = useGetUserById(message.sender)
	const userColor = game.userSettings[message.sender]?.color || 'rgb(55 65 81)' // fallback to gray-700
	const [name, setName] = useState(
		user?.userProfile?.name || message.fallbackName,
	)
	const { fetchDocument } = useGetDocumentById()

	const avatarSrc = useGetAvatarSrc({
		userId: message.sender,
		assumedCharacterId: message.assumedCharacterId,
		fallbackAvatarId: message.fallbackAvatarId,
		disableAssumedCharacterLookup: false,
	})

	const handleName = () => {
		if (message.assumedCharacterId) {
			const document = fetchDocument(message.assumedCharacterId)
			setName('As ' + document?.values?.name || 'Deleted Character')
		} else if (user?.userProfile?.name) {
			setName(user.userProfile.name)
		} else {
			setName(`${message.fallbackName} (removed)`)
		}
	}

	useEffect(handleName, [JSON.stringify(game.documents), user]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<MessageMenu onDelete={onDelete}>
			<div
				id={message._id}
				className={twMerge(
					'max-w-4/6 message inline-block transition-all duration-200',
					showSender ? 'my-2' : 'mt-1',
					className,
				)}
				style={style}
			>
				<div className='flex'>
					<Avatar src={avatarSrc} className={showSender ? '' : 'hidden'} />
					<div className={!showSender ? 'ml-9' : ''}>
						{showSender && (
							<SmallUppercase className='ml-2 mb-1'>{name}</SmallUppercase>
						)}
						<MessageBubble message={message} color={userColor} />
					</div>
				</div>
			</div>
		</MessageMenu>
	)
}

export default TextMessage

import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { INTERFACE_BACKGROUND_COLOR_HOVER } from '../interface/constants'

interface TabButtonProps {
	id: string
	label: string
	icon?: ReactNode
	accessory?: string
	isActive?: boolean
	onClick: () => void
}

const TabButton = ({
	id,
	label,
	icon,
	accessory,
	isActive,
	onClick,
}: TabButtonProps) => {
	const baseClasses =
		'-ml-4 flex w-full items-center gap-3 rounded-xl px-4 py-3 text-left text-sm transition-colors'

	// const stateClasses = isActive
	// 	? 'bg-black/10 text-gray-900'
	// 	: 'text-gray-600 hover:bg-black/5 hover:text-gray-900'

	return (
		<button
			key={id}
			role='tab'
			aria-selected={isActive}
			aria-controls={`tabpanel-${id}`}
			id={`tab-${id}`}
			onClick={onClick}
			className={twMerge(baseClasses)}
			style={{
				backgroundColor: isActive
					? INTERFACE_BACKGROUND_COLOR_HOVER
					: 'transparent',
			}}
		>
			<div className='flex flex-1 items-center gap-3'>
				{icon && icon}
				{label}
			</div>
			{accessory && <div className='text-gray-400'>{accessory}</div>}
		</button>
	)
}

export default TabButton

import { Eraser } from 'lucide-react'
import { useContext, useRef, useState } from 'react'
import styled from 'styled-components'
import GameContext from '../../contexts/game'
import ToolsContext from '../../contexts/tools'
import UsersContext from '../../contexts/users'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import useUser from '../../hooks/useUser'
import Toolbar from '../interface/toolbar/Toolbar'
import ToolButton from '../interface/toolbar/ToolButton'
import Popover from '../Popover'

export default function MeasureTools() {
	const { dispatchTools } = useContext(ToolsContext)
	const { dispatch } = useContext(GameContext)
	const { usersState } = useContext(UsersContext)
	const scene = useGetActiveScene()
	const { userId, isGM, color } = useUser()
	const [showPopover, setShowPopover] = useState(false)
	const buttonRef = useRef<HTMLButtonElement>(null)

	const playerColors = Object.values(usersState.users)
		.filter(user => user.userSettings.role !== 'gm' && user.userId !== userId)
		.map(user => user.userSettings.color)
		.slice(0, 2)

	const handleClearRulers = (clearType: 'all' | 'my' | 'players') => {
		if (!scene) return

		const currentRulers = scene.values.rulers?.rulers || {}
		let updatedRulers = { ...currentRulers }

		switch (clearType) {
			case 'all':
				updatedRulers = {}
				break
			case 'my':
				Object.keys(updatedRulers).forEach(key => {
					if (updatedRulers[key].userId === userId) {
						delete updatedRulers[key]
					}
				})
				break
			case 'players':
				Object.keys(updatedRulers).forEach(key => {
					if (updatedRulers[key].userId !== userId) {
						delete updatedRulers[key]
					}
				})
				break
		}

		dispatch({
			type: 'UPDATE_DOCUMENT',
			payload: {
				updatedDocument: {
					...scene,
					version: scene.version + 1,
					values: {
						...scene.values,
						rulers: {
							rulers: updatedRulers,
						},
					},
				},
			},
		})

		// Clear current measurement if we're clearing our own rulers
		if (clearType === 'my' || clearType === 'all') {
			dispatchTools({ type: 'SET_MEASURE_CLOSED', payload: false })
			dispatchTools({ type: 'CLEAR_MEASURE_POINTS' })
		}

		setShowPopover(false)
	}

	const handleNonGMClear = () => {
		if (!confirm('Are you sure you want to clear your rulers?')) return
		handleClearRulers('my')
	}

	return (
		<Toolbar>
			<ToolButton
				tooltip={isGM ? 'Clear Rulers' : 'Clear My Rulers'}
				onClick={() => (isGM ? setShowPopover(true) : handleNonGMClear())}
				ref={buttonRef}
			>
				<Eraser className='h-5 w-5' aria-hidden='true' />
			</ToolButton>

			{isGM && (
				<Popover
					id='clear-rulers-popover'
					open={showPopover}
					anchor={buttonRef}
					onClose={() => setShowPopover(false)}
					placement='bottom'
				>
					<PopoverContent>
						<MenuItem onClick={() => handleClearRulers('my')}>
							<span>Clear My Rulers</span>
							<ColorDots>
								<ColorDot color={color || '#FF0000'} />
							</ColorDots>
						</MenuItem>
						<MenuItem onClick={() => handleClearRulers('players')}>
							<span>Clear All Player Rulers</span>
							<ColorDots>
								{playerColors.map((playerColor, index) => (
									<ColorDot key={index} color={playerColor || '#CCCCCC'} />
								))}
							</ColorDots>
						</MenuItem>
						<MenuItem onClick={() => handleClearRulers('all')}>
							<span>Clear All Rulers</span>
							<ColorDots>
								<ColorDot color={color || '#FF0000'} />
								{playerColors.map((playerColor, index) => (
									<ColorDot key={index} color={playerColor || '#CCCCCC'} />
								))}
							</ColorDots>
						</MenuItem>
					</PopoverContent>
				</Popover>
			)}
		</Toolbar>
	)
}

const PopoverContent = styled.div`
	min-width: 240px;
	display: flex;
	flex-direction: column;
	gap: 4px;
`

const MenuItem = styled.button`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 12px;
	text-align: left;
	border-radius: 4px;

	&:hover {
		background-color: rgba(255, 255, 255, 0.1);
	}
`

const ColorDots = styled.div`
	display: flex;
	gap: 4px;
	align-items: center;
`

const ColorDot = styled.div<{ color: string }>`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: ${props => props.color};
`

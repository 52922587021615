import Color from 'color'
import { useMemo, useRef } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { twMerge } from 'tailwind-merge'
import { IMessage } from '../../../../shared/types/log'
import './bubble.css'
// import remarkGfm from 'remark-gfm'

const paragraphRenderer = ({ children }) => <p className='mb-4'>{children}</p>
const listRenderer = ({ children, ordered }) =>
	ordered ? (
		<ol className='ml-4 mb-4 list-outside list-decimal'>{children}</ol>
	) : (
		<ul className='ml-4 mb-4 list-outside list-disc'>{children}</ul>
	)
const tableRenderer = ({ children }) => (
	<table className='mb-4 border-collapse border-gray-300'>{children}</table>
)
const tableHeadRenderer = ({ children }) => (
	<thead className='border-b border-gray-300'>{children}</thead>
)
const tableBodyRenderer = ({ children }) => <tbody>{children}</tbody>
const tableRowRenderer = ({ children }) => <tr>{children}</tr>
const tableHeaderRenderer = ({ children }) => (
	<th className='px-4 py-2 text-left font-semibold'>{children}</th>
)
const tableCellRenderer = ({ children }) => (
	<td className='border border-gray-300 px-4 py-2'>{children}</td>
)
const strongRenderer = ({ children }) => (
	<strong className='font-semibold'>{children}</strong>
)
const emRenderer = ({ children }) => <em className='italic'>{children}</em>
// const linkRenderer = ({ children, href }) => (
// 	<a className='text-blue-500 hover:underline' href={href}>
// 		{children}
// 	</a>
// )

interface MessageBubbleProps {
	message?: IMessage
	color?: string
	children?: React.ReactNode
	className?: string
}

const MessageBubble: React.FC<MessageBubbleProps> = ({
	message,
	color,
	children,
	className,
}) => {
	const messageRef = useRef<HTMLDivElement>(null)

	const { backgroundColor, textColor } = useMemo(() => {
		const defaultBg = 'rgb(55 65 81 / 0.4)'
		if (!color)
			return { backgroundColor: defaultBg, textColor: 'text-gray-100' }

		const bgColor = Color(color).fade(0.8).toString()
		const isDark = false

		return {
			backgroundColor: bgColor,
			textColor: isDark ? 'text-gray-100' : 'text-gray-900',
		}
	}, [color])

	return (
		<div
			ref={messageRef}
			className={twMerge(
				'ml-2 inline-block rounded-2xl py-1.5 px-3 text-sm',
				textColor,
				className,
			)}
			style={{ backgroundColor }}
		>
			{message ? (
				<ReactMarkdown
					className='message'
					components={{
						p: paragraphRenderer,
						ul: listRenderer,
						ol: listRenderer,
						li: ({ children }) => <li className='mb-2'>{children}</li>,
						table: tableRenderer,
						thead: tableHeadRenderer,
						tbody: tableBodyRenderer,
						tr: tableRowRenderer,
						th: tableHeaderRenderer,
						td: tableCellRenderer,
						strong: strongRenderer,
						em: emRenderer,
					}}
					children={message.message}
				/>
			) : (
				children
			)}
		</div>
	)
}

export default MessageBubble

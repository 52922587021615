import useAllCollections from './useAllCollections'
import useGetAssetById from './useGetAssetById'
import useGetDocumentById from './useGetDocumentById'

const useGetThumbnailAsset = (id: string) => {
	const { document } = useGetDocumentById(id)
	const collections = useAllCollections()

	let thumbnailAssetId = ''

	if (document) {
		const collection = collections.find(c => c.type === document.type)

		// First check if there's a token field
		if (document.values?.token) {
			thumbnailAssetId = document.values.token
		}

		// If no token found, check the thumbnail fields
		if (!thumbnailAssetId) {
			collection?.thumbnailField?.forEach(field => {
				const value = document.values?.[field]
				if (value) {
					thumbnailAssetId = value
				}
			})
		}
	}

	const { asset } = useGetAssetById(thumbnailAssetId)
	return thumbnailAssetId ? asset : null
}

export default useGetThumbnailAsset

import DiceBox from '@3d-dice/dice-box'
import DiceParser from '@3d-dice/dice-parser-interface'
import { DiceExpressionRoll, DiceRollResult } from 'dice-roller-parser'
import { Trash2 } from 'lucide-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { useGame } from '../../hooks/useGame'
import { INTERFACE_BRIGHT_GOLD_COLOR } from '../interface/constants'
import XsType from '../interface/text/XsType'
import Toolbar from '../interface/toolbar/Toolbar'
import Overlapper from '../Overlapper'
import ColorSwatch from '../settings/ColorSwatch'
import Translucency from '../Translucency'
import AdvRollBtn from './AdvRollButton'
import './dice.css'

export interface DicePoolEntry {
	die: string
	count: number
}

const DRP = new DiceParser()
const DICE_CANVAS_ID = 'dice-canvas'
const ASSET_PATH = '/assets/dice-box/'

function calculateDiceScale(viewportHeight: number): number {
	const baseHeight = 1080
	const baseScale = 3.25
	const scaleFactor = viewportHeight / baseHeight
	return baseScale / scaleFactor
}

const rpgDiceConfig = ['d4', 'd6', 'd8', 'd10', 'd12', 'd20', 'd100']

interface DiceToolsProps {
	color?: string
	diceConfig?: string[]
	showColorPicker?: boolean
	onColorChange?: (color: string) => void
	onRollResults?: (
		results: DiceRollResult | DiceExpressionRoll,
		notation: string,
	) => void
	rollNotation?: string
	onDicePoolChange?: (hasActiveDice: boolean) => void
}

export default function DiceTools({
	color = '#222222',
	diceConfig = rpgDiceConfig,
	showColorPicker = true,
	onColorChange,
	onRollResults = () => {},
	rollNotation,
	onDicePoolChange,
}: DiceToolsProps) {
	const { game } = useGame()
	const enabledDice = game.dice?.enabledDice || rpgDiceConfig

	// Return null if no dice are enabled
	if (!enabledDice.length) return null

	const [diceColor, setDiceColor] = useState(color)
	const [dicePool, setDicePool] = useState<DicePoolEntry[]>([])
	const diceRef = useRef<DiceBox | null>(null)
	const [isInitialized, setIsInitialized] = useState(false)
	const [isRolling, setIsRolling] = useState(false)
	const [needsClear, setNeedsClear] = useState(false)
	const currentNotationRef = useRef<string | undefined>(undefined)

	useEffect(() => {
		onDicePoolChange?.(dicePool.length > 0)
	}, [dicePool, onDicePoolChange])

	const addToDicePool = useCallback((die: string) => {
		setDicePool(prev => {
			const existingEntry = prev.find(entry => entry.die === die)
			if (existingEntry) {
				return prev.map(entry =>
					entry.die === die ? { ...entry, count: entry.count + 1 } : entry,
				)
			}
			return [...prev, { die, count: 1 }]
		})
	}, [])

	const clearDicePool = useCallback(() => {
		setDicePool([])
	}, [])

	const handleRollComplete = useCallback(
		(results: DiceRollResult) => {
			const rerolls = DRP.handleRerolls(results)

			if (rerolls.length) {
				rerolls.forEach((roll: DiceRollResult) => diceRef.current?.add(roll))
				return rerolls
			}

			const finalResults = DRP.parseFinalResults(results)
			onRollResults(finalResults, currentNotationRef.current)
			currentNotationRef.current = undefined
			setIsRolling(false)
			setNeedsClear(true)
			clearDicePool()
		},
		[onRollResults, clearDicePool],
	)

	const rollDice = useCallback(
		async (notation: string) => {
			if (!isInitialized || isRolling || !diceRef.current) return
			currentNotationRef.current = notation
			try {
				const parsedNotation = DRP.parseNotation(notation)
				setIsRolling(true)
				setNeedsClear(false)
				await diceRef.current.show().roll(parsedNotation)
			} catch {
				setIsRolling(false)
			}
		},
		[isInitialized, isRolling],
	)

	const rollDicePool = useCallback(() => {
		if (dicePool.length === 0) return

		const notation = dicePool
			.map(entry => `${entry.count}${entry.die}`)
			.join('+')

		rollDice(notation)
		clearDicePool()
	}, [dicePool, rollDice, clearDicePool])

	const updateDiceTheme = useCallback(
		(newColor: string) => {
			if (diceRef.current && isInitialized) {
				try {
					diceRef.current.updateConfig({ themeColor: newColor })
				} catch {
					// Theme update failed, but this is not critical
				}
			}
		},
		[isInitialized],
	)

	const handleColorChange = useCallback(
		(newColor: string) => {
			setDiceColor(newColor)
			updateDiceTheme(newColor)
			onColorChange?.(newColor)
		},
		[onColorChange, updateDiceTheme],
	)

	const setupDiceBox = useCallback(async () => {
		try {
			const viewportHeight = window.innerHeight
			const calculatedScale = calculateDiceScale(viewportHeight)

			diceRef.current = new DiceBox({
				id: DICE_CANVAS_ID,
				assetPath: ASSET_PATH,
				startingHeight: 5,
				throwForce: 6,
				spinForce: 4,
				lightIntensity: 1.2,
				scale: calculatedScale,
				friction: 0.8,
				linearDamping: 0.35,
				angularDamping: 0.35,
				restitution: 0.65,
				themeColor: diceColor,
				shadowQuality: 'high',
				bumpMapping: true,
				sounds: true,
				textureSize: 512,
				renderShadows: true,
				shadowTransparency: 0.8,
				useHighDPI: true,
			})

			await diceRef.current.init()
			diceRef.current.onRollComplete = handleRollComplete
			setIsInitialized(true)
		} catch {
			setIsInitialized(false)
		}
	}, [diceColor, handleRollComplete])

	useEffect(() => {
		setupDiceBox()
		return () => {
			if (diceRef.current) {
				diceRef.current.destroy?.()
				diceRef.current = null
			}
		}
	}, [setupDiceBox])

	useEffect(() => {
		if (isInitialized) {
			updateDiceTheme(diceColor)
		}
	}, [isInitialized, diceColor, updateDiceTheme])

	useEffect(() => {
		setDiceColor(color)
	}, [color])

	useEffect(() => {
		const handleClick = () => {
			if (needsClear && diceRef.current) {
				diceRef.current.clear()
				setNeedsClear(false)
			}
		}

		window.addEventListener('click', handleClick)
		return () => window.removeEventListener('click', handleClick)
	}, [needsClear])

	useEffect(() => {
		if (rollNotation && isInitialized && !isRolling) {
			rollDice(rollNotation)
		}
	}, [rollNotation, isInitialized, isRolling, rollDice])

	useEffect(() => {
		const handleResize = () => {
			if (diceRef.current) {
				const newScale = calculateDiceScale(window.innerHeight)
				diceRef.current.updateConfig({ scale: newScale })
			}
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	const removeFromDicePool = useCallback((die: string) => {
		setDicePool(prev => {
			const existingEntry = prev.find(entry => entry.die === die)
			if (existingEntry && existingEntry.count > 1) {
				return prev.map(entry =>
					entry.die === die ? { ...entry, count: entry.count - 1 } : entry,
				)
			}
			return prev.filter(entry => entry.die !== die)
		})
	}, [])

	const buttonElements = useMemo(() => {
		const configToUse = diceConfig?.length ? diceConfig : rpgDiceConfig
		return configToUse
			.filter(die => enabledDice.includes(die))
			.map((die, index) => {
				const poolEntry = dicePool.find(entry => entry.die === die)
				const isD100 = die === 'd100'

				return (
					<div key={index} className='transition-all duration-300'>
						<div className='relative'>
							<AdvRollBtn
								label={die.toUpperCase()}
								notation={die}
								tooltip={die}
								onRoll={() => addToDicePool(die)}
								onRemove={removeFromDicePool}
								disabled={!isInitialized || isRolling}
								icons={isD100 ? ['d10', 'd10'] : [die]}
							/>
							{poolEntry && (
								<div
									className='absolute -top-1 left-1/2 flex h-6 w-6 -translate-y-1/2 -translate-x-1/2 items-center justify-center rounded-full bg-blue-500 text-xs font-bold text-white'
									style={{
										backgroundColor: INTERFACE_BRIGHT_GOLD_COLOR,
									}}
								>
									{poolEntry.count}
								</div>
							)}
						</div>
					</div>
				)
			})
	}, [
		diceConfig,
		isInitialized,
		isRolling,
		addToDicePool,
		removeFromDicePool,
		dicePool,
		enabledDice,
	])

	return (
		<div className='pointer-events-auto flex justify-center'>
			<Toolbar>
				<AdvRollBtn
					label={<Trash2 className='h-5 w-5' />}
					onRoll={clearDicePool}
					notation=''
					disabled={dicePool.length === 0}
					tooltip='Clear Dice'
				/>
				<div className='max-w-[300px]'>
					<Overlapper>
						{buttonElements}
						{showColorPicker && (
							<div
								className={twMerge(
									'rounded-full p-2 opacity-0 transition-all duration-300 group-hover:opacity-100',
									Translucency,
								)}
							>
								<ColorSwatch
									color={diceColor}
									colorName='dice'
									setEnableClickOutside={() => {}}
									onChange={handleColorChange}
									className='flex aspect-square h-5 w-5 flex-shrink-0 items-center justify-center'
								/>
							</div>
						)}
					</Overlapper>
				</div>
				<AdvRollBtn
					label={<XsType>Roll</XsType>}
					onRoll={rollDicePool}
					tooltip='Roll Dice'
					notation=''
					disabled={isRolling || dicePool.length === 0}
				/>
			</Toolbar>
		</div>
	)
}

import { Container } from '@pixi/react'
import ObjectId from 'bson-objectid'
import { Viewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import { useContext, useEffect, useRef } from 'react'
import GameContext from '../../../contexts/game'
import { useGrid } from '../../../contexts/grid'
import ToolsContext from '../../../contexts/tools'
import useGetActiveScene from '../../../hooks/useGetActiveScene'
import useUser from '../../../hooks/useUser'
import MeasurementDisplay from './MeasurementDisplay'
import MeasurementInteractionLayer from './MeasurementInteractionLayer'
import MeasurementLine from './MeasurementLine'

interface Props {
	viewport: Viewport
}

export default function MeasurementTool({ viewport }: Props) {
	const { toolsState, dispatchTools } = useContext(ToolsContext)
	const { mapDimensions } = useGrid()
	const { userId } = useUser()
	const { dispatch } = useContext(GameContext)
	const scene = useGetActiveScene()
	const containerRef = useRef<PIXI.Container>(null)
	const currentRulerId = useRef<string>(new ObjectId().toHexString())
	const lastUpdateRef = useRef<string>('')

	function handleKeyDown(e: KeyboardEvent) {
		if (toolsState.measure.isClosed) return
		if (e.key === 'Escape') {
			dispatchTools({ type: 'SET_MEASURE_CLOSED', payload: true })
		}
	}

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown)
		return () => window.removeEventListener('keydown', handleKeyDown)
	}, [])

	// Clear measure points only when entering measure mode with no active ruler
	useEffect(() => {
		if (toolsState.mode === 'measure' && !toolsState.measure.points.length) {
			// Generate new ID for next measurement
			currentRulerId.current = new ObjectId().toHexString()
			// Reset state only if we don't have an active ruler
			dispatchTools({ type: 'SET_MEASURE_CLOSED', payload: false })
		}
	}, [toolsState.mode])

	// Update ruler in scene document when points or closed state changes
	useEffect(() => {
		if (!scene) return

		// Skip if no points to save
		if (toolsState.measure.points.length === 0) return

		// Create update key to check if we need to update
		const updateKey = JSON.stringify({
			points: toolsState.measure.points,
			isClosed: toolsState.measure.isClosed,
		})

		// Skip if nothing has changed
		if (updateKey === lastUpdateRef.current) return
		lastUpdateRef.current = updateKey

		// Get current rulers and filter out any existing ruler for this user
		const currentRulers = scene.values.rulers?.rulers || {}
		const filteredRulers = Object.fromEntries(
			Object.entries(currentRulers).filter(([_, r]) => r.userId !== userId),
		)

		// Create the new ruler
		const newRuler = {
			id: currentRulerId.current,
			userId,
			points: toolsState.measure.points,
			isClosed: toolsState.measure.isClosed,
			visibility: 'all',
		}

		// Update the scene with the new ruler
		dispatch({
			type: 'UPDATE_DOCUMENT',
			payload: {
				updatedDocument: {
					...scene,
					version: scene.version + 1,
					values: {
						...scene.values,
						rulers: {
							rulers: {
								...filteredRulers,
								[currentRulerId.current]: newRuler,
							},
						},
					},
				},
			},
		})

		// Reset for next ruler if current one was closed
		if (toolsState.measure.isClosed) {
			dispatchTools({ type: 'CLEAR_MEASURE_POINTS' })
			dispatchTools({ type: 'SET_MEASURE_CLOSED', payload: false })
			currentRulerId.current = new ObjectId().toHexString()
		}
	}, [
		toolsState.measure.points,
		toolsState.measure.isClosed,
		userId,
		scene?._id,
	])

	// Clean up incomplete ruler when measurement tool is deactivated
	useEffect(() => {
		if (!scene || toolsState.mode !== 'measure') {
			// Only clean up if the ruler isn't closed (incomplete)
			if (
				!toolsState.measure.isClosed &&
				scene?.values.rulers?.rulers?.[currentRulerId.current]
			) {
				const currentRulers = scene.values.rulers?.rulers || {}
				const remainingRulers = { ...currentRulers }
				delete remainingRulers[currentRulerId.current]

				dispatch({
					type: 'UPDATE_DOCUMENT',
					payload: {
						updatedDocument: {
							...scene,
							version: scene.version + 1,
							values: {
								...scene.values,
								rulers: {
									rulers: remainingRulers,
								},
							},
						},
					},
				})
			}
		}
	}, [toolsState.mode, scene?._id, toolsState.measure.isClosed])

	// Split points into fixed segments for current user's ruler
	const fixedPoints = toolsState.measure.points

	return (
		<Container
			ref={containerRef}
			x={0}
			y={0}
			hitArea={
				new PIXI.Rectangle(0, 0, mapDimensions.width, mapDimensions.height)
			}
			eventMode={toolsState.mode === 'measure' ? 'static' : 'none'}
		>
			{/* Show all saved rulers regardless of mode */}
			{scene?.values.rulers?.rulers &&
				Object.values(scene.values.rulers.rulers)
					.filter(ruler => ruler.id !== currentRulerId.current)
					.map(ruler => (
						<Container key={ruler.id}>
							<MeasurementLine
								points={ruler.points}
								userId={ruler.userId}
								isOtherUser={ruler.userId !== userId}
							/>
							<MeasurementDisplay
								points={ruler.points}
								isClosed={ruler.isClosed}
								userId={ruler.userId}
							/>
						</Container>
					))}

			{/* Show current user's ruler */}
			{fixedPoints.length > 0 && (
				<>
					<MeasurementLine points={fixedPoints} userId={userId} />
					<MeasurementDisplay
						points={fixedPoints}
						isClosed={toolsState.measure.isClosed}
						userId={userId}
					/>
				</>
			)}

			{/* Only show interactive measurement container in measure mode */}
			{toolsState.mode === 'measure' && (
				<MeasurementInteractionLayer
					points={toolsState.measure.points}
					viewport={viewport}
					isClosed={toolsState.measure.isClosed}
					onPointAdded={point => {
						dispatchTools({
							type: 'ADD_MEASURE_POINT',
							payload: point,
						})
					}}
					onMeasurementClosed={() => {
						// Just mark it as closed - the effect will handle cleanup after saving
						dispatchTools({ type: 'SET_MEASURE_CLOSED', payload: true })
					}}
				/>
			)}
		</Container>
	)
}
